import type { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import React from 'react'
import { NextSeo } from 'next-seo'
import { groq } from 'next-sanity'
import {
  getRouteByPath,
  getClient,
  getSiteConfiguration,
  imageUrlBuilder,
} from '@the-headless-club/services/sanity-service'
import { PageBuilder } from '@the-headless-club/page-builder'
import { generateLocalePaths, logger } from '@the-headless-club/utils'
import { PageBuilderSection, PageResponse } from '@the-headless-club/models'
import { useRouter } from 'next/router'

const Page: NextPage<PageResponse<PageBuilderSection[]>> = ({
  pageBuilder,
  seo,
  disallowRobots,
}) => {
  const { asPath } = useRouter()
  const fullUrl = `${process.env.BASE_URL}${asPath}`
  return (
    <div>
      {seo ? (
        <NextSeo
          nofollow={disallowRobots}
          noindex={disallowRobots}
          title={seo.metaTitle}
          description={seo.metaDescription}
          canonical={fullUrl}
          openGraph={{
            url: fullUrl,
            type: 'website',
            title: seo.ogTitle,
            description: seo.ogDescription,
            images: [
              {
                url:
                  seo.ogImage?.mediaPayload?.image.asset &&
                  imageUrlBuilder
                    .image(seo.ogImage?.mediaPayload?.image)
                    .width(1200)
                    .height(630)
                    .quality(75)
                    .url(),
                width: 1200,
                height: 630,
                alt: seo?.ogImage?.alt,
              },
            ],
          }}
          twitter={{
            cardType: 'summary_large_image',
          }}
        />
      ) : (
        <NextSeo
          noindex={disallowRobots}
          nofollow={disallowRobots}
          canonical={fullUrl}
        />
      )}
      {pageBuilder?.map((section, index) => {
        return (
          <PageBuilder
            section={{ ...section, priority: index < 2 }}
            key={section._key}
          />
        )
      })}
    </div>
  )
}

export const getStaticPaths: GetStaticPaths = async ({ defaultLocale }) => {
  const paths: string[] = await getClient(false).fetch(
    groq`*[!(_id in path('drafts.**')) && defined(path.current)][].path.current`,
  )

  return {
    paths: generateLocalePaths(paths, defaultLocale),
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps<
  PageResponse<PageBuilderSection[]>
> = async ({ params, locale, preview = false }) => {
  const { paths } = params

  if (typeof paths === 'string') {
    throw new Error('paths must be passed as an array. Received string')
  }

  const path = `/${paths ? paths.join('/') : ''}`

  const pageData = await getRouteByPath(path, locale, preview)

  if (!pageData) {
    logger.error(`Cannot fetch pageData for ${path}`)
    return {
      notFound: true,
    }
  }

  const siteConfiguration = await getSiteConfiguration(locale, preview)

  return {
    props: {
      ...pageData,
      siteConfiguration,
    },
    revalidate: 10,
  }
}

export default Page
